export default {
    routerList: [
      {
        path: '/advice/adManage',
        component: () => import('@/views/advice/manage/'),
        name: '意见管理',
        children: []
      },
      {
        path: '/advice/adWrite',
        component: () => import('@/views/advice/write/index.vue'),
        name: '意见填写',
        children: []
      },

    ]
  }
  