<template>
  <div class="breadcrumbnewBox">
    <el-page-header @back="goBack" title="返回" v-if="!list.length">
      <template #content>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="item in routeName" :key="item.path">
            <i
              @click="goHome()"
              v-if="item.path == '/index'"
              class="iconfont icon-quanbuyewu wordColor homeIcon"
            ></i>
            <span
              class="homeIcon"
              @click="goRouter(item)"
              v-if="item.path != '/index'"
              >{{ item.name }}</span
            >
          </el-breadcrumb-item>
        </el-breadcrumb>
      </template>
    </el-page-header>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="list.length">
      <el-breadcrumb-item v-for="item in routeName" :key="item.path">
        <i
          @click="goHome()"
          v-if="item.path == '/index'"
          class="iconfont icon-quanbuyewu wordColor homeIcon"
        ></i>
        <span
          class="homeIcon"
          @click="goRouter(item)"
          v-if="item.path != '/index'"
          >{{ item.name }}</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
import { } from '@/api/api'
import { useRouter } from 'vue-router'
export default {
  name: 'breadcrumbnewBox',
  props: ['list'],
  components: {
  },
  data () {
    return {
      routeList: []
    }
  },
  setup () {
    const router = useRouter()
    function linkToAbout (e) {
      router.push({
        path: e.path,
        query: { code: e.code }
      })
    }
    return {
      linkToAbout
    }
  },
  computed: {
    routeName () {
      let arr = []
      this.routeListClear()
      const path = (this.$route.matched[this.$route.matched.length - 1]).path
      const list = JSON.parse(sessionStorage.getItem('menuList'))
      for (let i = 0; i < list.length; i++) {
        if (list[i].path === path && list[i].code === this.$route.query.code) {
          arr = [...[{ path: '/index' }], ...this.createTreeData(list, list[i].code)]
        }
      }
      return arr
    }
  },
  mounted () {

  },
  created () {
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    createTreeData (list, pcode) {
      list.forEach((item, index) => {
        if (item.code === pcode) {
          this.routeList.unshift(item)
          this.createTreeData(list, item.pcode)
        }
      })
      return this.routeList
    },
    routeListClear () {
      this.routeList = []
    },
    goRouter (e) {
      sessionStorage.setItem('menunewPcode', e.code)
      this.linkToAbout(e)
    },
    goHome () {
      this.$router.push('/terrace')
    }
  }
}
</script>
<style lang="scss">
.breadcrumbnewBox {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  background: #f9f9f9;
  .homeIcon {
    cursor: pointer;
  }
  .homeIcon:hover {
    opacity: 0.5;
  }
  .el-breadcrumb__item {
    display: flex;
    align-items: center;
  }
  .el-breadcrumb {
    display: flex;
  }
}
</style>
