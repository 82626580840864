export default {
  routerList: [
    {
      path: '/solicitationOpinions/manage',
      component: () => import('@/views/solicitationOpinions/manage/'),
      name: 'solicitationOpinions-manage',
      children: []
    },
    {
      path: '/solicitationOpinions/collect',
      component: () => import('@/views/solicitationOpinions/collect/index.vue'),
      name: 'solicitationOpinions-collect',
      children: []
    }

  ]
}
