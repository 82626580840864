export default function a () {
  function getStr (b) {
    switch (b) {
      case 'xtgly':
      case 'admins':
        return '010' // 系统管理员
      case 'cw':
        return '002' // 常委
      case 'qgrddb':
        return '020' // 全国人大代表
      case 'shengrddb':
        return '011' // 省人大代表
      case 'shirddb':
        return '008' // 市人大代表
      case 'xrddb':
        return '023' // 县人大代表
      case 'xzjdrddb':
        return '014' // 乡镇（街道）人大代表
      case 'rdzx':
        return '004' // 人大主席
      case 'bldw':
        return '006' // 办理单位
      case 'llzzz':
        return '007' // 代表之家站长
      case 'dbxz':
        return '009' // 代表小组
      case 'dbtgly':
        return '012' // 代表团管理员
      case 'pgdbt':
        return '013' // 评估代表团
      case 'llzfzz':
        return '015' // 代表之家副站长
      case 'pgw':
        return '016' // 评估委
      case 'wzgly':
        return '017' // 网站管理员
      case 'llzlly':
        return '018' // 代表之家联络员
      case 'zfry':
        return '019' // 政府人员
      case 'xzjdrdzxtgw':
        return '022' // 乡镇(街道)人大主席团(工委)
      case 'rdgws':
        return '024' // 人大各委室
      case 'xgw':
        return '025' // 选工委
      case 'rdbgs':
        return '026' // 人大办公室
      case 'dpsj':
        return '028' // 大屏
      case 'gxzjdrd':
        return '029' // 大屏
      case 'wylls':
        return '030' // 委员联络室
      case 'yjs':
        return '031' // 研究室
      case 'jbzcy':
        return '035' // 简报组成员
      case 'yfly':
        return '037' // 一府两院
      case 'jdgl':
        return '038' // 街道管理员
      default:
        return ''
    }
  }
  const str = JSON.parse(sessionStorage.getItem('groupsList'))
  const tempArr = []
  if (str) {
    for (const i in str) {
      tempArr.push(getStr(str[i]))
    }
    return tempArr
  } else {
    return []
  }
}
