import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    menuStorageValue: 0,
    tableConfig: {
      loading: false,
      tableData: []
    },
    exportLoading: 0,
    exportLoadingText: '',
    publicObj: {},
    questionObj: {},
    previewImageShow: false, // 附件预览弹窗显示隐藏控制
    previewImageUrl: '', // 附件预览图片路径
    previewImageType: '', // 附件预览文件类型
    printObj: {
      year: new Date().getFullYear(), // 年份
      show: false, // 组件显示隐藏
      data: [], // 列表数据
      type: '', // 类型
      title: '', // 标题
      isSum: false // 是否需要总计
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})
