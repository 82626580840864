
export default {
  routerList: [
    {
      path: '/dataCenter/suggest',
      component: () => import('@/views/dataCenter/suggest/index.vue'),
      name: '建议信息',
      children: []
    },
    {
      path: '/dataCenter/supervise',
      component: () => import('@/views/dataCenter/supervise/index.vue'),
      name: '随手拍信息',
      children: []
    },
    {
      path: '/dataCenter/representative',
      component: () => import('@/views/dataCenter/representative/index.vue'),
      name: '代表信息',
      children: []
    },
    {
      path: '/dataCenter/workingPersonnel',
      component: () => import('@/views/dataCenter/workingPersonnel/index.vue'),
      name: '机关工作人员信息',
      children: []
    },
    {
      path: '/dataCenter/resumption',
      component: () => import('@/views/dataCenter/resumption/index.vue'),
      name: '履职情况统计',
      children: []
    },
    {
      path: '/dataCenter/usedaily',
      component: () => import('@/views/dataCenter/usedaily/index.vue'),
      name: '系统使用情况日报表（分市县）',
      children: []
    }
  ]
}
