export default {
  routerList: [
    {
      path: '/lettersVisits/allLettersVisits',
      component: () => import('@/views/lettersVisits/allLettersVisits/index.vue'),
      name: '所有信访',
      children: []
    },
    {
      path: '/lettersVisits/LettersExamine',
      component: () => import('@/views/lettersVisits/LettersExamine/index.vue'),
      name: '待审核信访信息',
      children: []
    },

  ]
}
