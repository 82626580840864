export default {
  routerList: [
    {
      path: '/addressBookManage/addressBook',
      component: () => import('@/views/addressBookManage/addressBook/index.vue'),
      name: '通讯录-',
      children: []
    },
    {
      path: '/addressBookManage/addressBookGroup',
      component: () => import('@/views/addressBookManage/addressBookGroup/index.vue'),
      name: '通讯录分组-',
      children: []
    }
  ]
}
