export default {
  routerList: [
    {
      path: '/suggest/allSuggest',
      component: () => import('@/views/suggest/allSuggest/index.vue'),
      name: '所有建议',
      children: []
    },
    {
      path: '/suggest/firstTrialSuggest',
      component: () => import('@/views/suggest/firstTrialSuggest/index.vue'),
      name: '待初审建议',
      children: []
    },
    {
      path: '/suggest/secondTrialSuggest',
      component: () => import('@/views/suggest/secondTrialSuggest/index.vue'),
      name: '待复审建议',
      children: []
    },
    {
      path: '/suggest/sugHeaded',
      component: () => import('@/views/suggest/sugHeaded/index.vue'),
      name: '我的领衔建议',
      children: []
    },
    {
      path: '/suggest/sugFollow',
      component: () => import('@/views/suggest/sugFollow/index.vue'),
      name: '跟踪办理建议',
      children: []
    },
    {
      path: '/suggest/waitExamin',
      component: () => import('@/views/suggest/waitExamin/index.vue'),
      name: '待审查建议',
      children: []
    },
    {
      path: '/suggest/meetingTrial',
      component: () => import('@/views/suggest/meetingTrial/index.vue'),
      name: '大会初审建议',
      children: []
    },
    {
      path: '/suggest/closeTrial',
      component: () => import('@/views/suggest/closeTrial/index.vue'),
      name: '闭会初审建议',
      children: []
    },
    {
      path: '/suggest/publicRecommendation',
      component: () => import('@/views/suggest/publicRecommendation/index.vue'),
      name: '公开建议',
      children: []
    },
    {
      path: '/suggest/yearResults',
      component: () => import('@/views/suggest/yearResults/index.vue'),
      name: '建议办理情况征求意见',
      children: []
    },
    {
      path: '/suggest/sugDeal',
      component: () => import('@/views/suggest/sugDeal/index.vue'),
      name: '待交办建议',
      children: []
    },
    {
      path: '/suggest/sugCommited',
      component: () => import('@/views/suggest/sugCommited/index.vue'),
      name: '已交办建议',
      children: []
    },
    {
      path: '/suggest/sugAssign',
      component: () => import('@/views/suggest/sugAssign/index.vue'),
      name: '交办建议',
      children: []
    },
    {
      path: '/suggest/sugReturn',
      component: () => import('@/views/suggest/sugReturn/index.vue'),
      name: '撤回建议',
      children: []
    },
    {
      path: '/suggest/draftsSuggest',
      component: () => import('@/views/suggest/draftsSuggest/index.vue'),
      name: '草稿箱建议',
      children: []
    },
    {
      path: '/suggest/sugTransact',
      component: () => import('@/views/suggest/sugTransact/index.vue'),
      name: '待办理建议',
      children: []
    },
    {
      path: '/suggest/sugHandle',
      component: () => import('@/views/suggest/sugHandle/index.vue'),
      name: '办理中建议',
      children: []
    },
    {
      path: '/suggest/sugJointly',
      component: () => import('@/views/suggest/sugJointly/index.vue'),
      name: '我的联名建议',
      children: []
    },
    {
      path: '/suggest/sugAnswer',
      component: () => import('@/views/suggest/sugAnswer/index.vue'),
      name: '已答复建议',
      children: []
    },
    {
      path: '/suggest/sugAgain',
      component: () => import('@/views/suggest/sugAgain/index.vue'),
      name: '重新办理建议',
      children: []
    },
    {
      path: '/suggest/sugDelay',
      component: () => import('@/views/suggest/sugDelay/index.vue'),
      name: '延期建议（办理单位菜单）',
      children: []
    },
    {
      path: '/suggest/sugPostpone',
      component: () => import('@/views/suggest/sugPostpone/index.vue'),
      name: '延期建议（选工委菜单）',
      children: []
    },
    {
      path: '/suggest/sugUntread',
      component: () => import('@/views/suggest/sugUntread/index.vue'),
      name: '申请撤回建议',
      children: []
    },
    {
      path: '/suggest/sugBan',
      component: () => import('@/views/suggest/sugBan/index.vue'),
      name: '已办结建议',
      children: []
    },
    {
      path: '/suggest/sugReturn',
      component: () => import('@/views/suggest/sugReturn/index.vue'),
      name: '撤回建议',
      children: []
    },
    {
      path: '/suggest/sugNoAccept',
      component: () => import('@/views/suggest/sugNoAccept/index.vue'),
      name: '不予受理建议',
      children: []
    },
    {
      path: '/suggest/sugEevaluate',
      component: () => import('@/views/suggest/sugEevaluate/index.vue'),
      name: '已测评建议',
      children: []
    },
    {
      path: '/suggest/sugcontacts',
      component: () => import('@/views/suggest/sugcontacts/index.vue'),
      name: '建议联系人',
      children: []
    },
    {
      path: '/suggest/repeatProposal',
      component: () => import('@/views/suggest/repeatProposal/index.vue'),
      name: '疑似重复建议',
      children: []
    },
    {
      path: '/suggest/yearResults',
      component: () => import('@/views/suggest/yearResults/index.vue'),
      name: '建议办理情况征求意见',
      children: []
    },
    {
      path: '/suggest/solicitation',
      component: () => import('@/views/suggest/solicitation/index.vue'),
      name: '联名人征集',
      children: []
    },
    {
      path: '/suggest/suggestionSelection',
      component: () => import('@/views/suggest/suggestionSelection/index.vue'),
      name: '重点建议评选',
      children: []
    },
    {
      path: '/suggest/proposalSetUp',
      component: () => import('@/views/suggest/proposalSetUp/index.vue'),
      name: '建议设置',
      children: []
    },
    {
      path: '/suggest/sugEcharts',
      component: () => import('@/views/suggest/sugEcharts/index.vue'),
      name: '建议统计',
      children: []
    },
    {
      path: '/suggest/statusStatistics',
      component: () => import('@/views/suggest/statusStatistics/index.vue'),
      name: '建议提交统计',
      children: []
    }
  ]
}
