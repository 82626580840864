import moment from 'moment'
import { getUploadAuthentication, fileUpload } from '@/api/api'
import * as Quill from 'quill'
import { ElLoading } from 'element-plus'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 设置字体
const Font = Quill.import('attributors/style/font') // 引入这个后会把样式写在style上
const fonts = ['Microsoft-YaHei', 'SimSun', 'SimHei', 'KaiTi', 'FangSong', false]
Font.whitelist = fonts // 将字体加入到白名单
Quill.register(Font, true)
// 设置字体大小
const fontSizeStyle = Quill.import('attributors/style/size') // 引入这个后会把样式写在style上
const fontSizes = ['10px', '12px', '14px', '16px', '18px', '20px', '21px', '24px', false]
fontSizeStyle.whitelist = fontSizes
Quill.register(fontSizeStyle, true)

const Align = Quill.import('attributors/style/align')
Quill.register(Align, true)
const Direction = Quill.import('attributors/style/direction')
Quill.register(Direction, true)

// loading事件
let loadingInstance = null
// 上传配置
const uploadConfig = {
  action: 'api' + '/home/news/upload/files', // 必填参数 图片上传地址
  methods: 'POST', // 必填参数 图片上传方式
  token: sessionStorage.getItem('token'), // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
  name: 'fileData', // 必填参数 文件的参数名
  size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
  acceptImage: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon', // 可选 可上传的图片格式
  acceptVideo: 'video/mp4, ' // 可选 可上传的图片格式
}
// toolbar标题
const titleConfig = [
  { Choice: '.ql-insertMetric', title: '跳转配置' },
  { Choice: '.ql-bold', title: '加粗' },
  { Choice: '.ql-italic', title: '斜体' },
  { Choice: '.ql-underline', title: '下划线' },
  { Choice: '.ql-header', title: '段落格式' },
  { Choice: '.ql-strike', title: '删除线' },
  { Choice: '.ql-blockquote', title: '块引用' },
  { Choice: '.ql-code', title: '插入代码' },
  { Choice: '.ql-code-block', title: '插入代码段' },
  { Choice: '.ql-font', title: '字体' },
  { Choice: '.ql-size', title: '字体大小' },
  { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
  { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
  { Choice: '.ql-direction', title: '文本方向' },
  { Choice: '.ql-header[value="1"]', title: 'h1' },
  { Choice: '.ql-header[value="2"]', title: 'h2' },
  { Choice: '.ql-align', title: '对齐方式' },
  { Choice: '.ql-color', title: '字体颜色' },
  { Choice: '.ql-background', title: '背景颜色' },
  { Choice: '.ql-image', title: '图像' },
  { Choice: '.ql-video', title: '视频' },
  { Choice: '.ql-link', title: '添加链接' },
  { Choice: '.ql-formula', title: '插入公式' },
  { Choice: '.ql-clean', title: '清除字体格式' },
  { Choice: '.ql-script[value="sub"]', title: '下标' },
  { Choice: '.ql-script[value="super"]', title: '上标' },
  { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
  { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
  { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
  { Choice: '.ql-size .ql-picker-item[data-value="10px"]', title: '10px' },
  { Choice: '.ql-size .ql-picker-item[data-value="12px"]', title: '12px' },
  { Choice: '.ql-size .ql-picker-item[data-value="14px"]', title: '14px' },
  { Choice: '.ql-size .ql-picker-item[data-value="16px"]', title: '16px' },
  { Choice: '.ql-size .ql-picker-item[data-value="18px"]', title: '18px' },
  { Choice: '.ql-size .ql-picker-item[data-value="20px"]', title: '20px' },
  { Choice: '.ql-size .ql-picker-item[data-value="21px"]', title: '21px' },
  { Choice: '.ql-size .ql-picker-item[data-value="24px"]', title: '24px' },
  { Choice: '.ql-font .ql-picker-item:last-child', title: '默认字体' },
  { Choice: '.ql-size .ql-picker-item:last-child', title: '默认大小' },
  { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
  { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
]
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
  ['blockquote', 'code-block'], // 引用
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标
  [{ indent: '-1' }, { indent: '+1' }], // 缩进
  [{ direction: 'rtl' }], // 文本方向
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  [{ font: fonts }],
  [{ size: fontSizes }], // 字体大小
  [{ align: [] }], // 对齐方式
  ['clean'], // 清除文本格式
  ['link', 'image', 'video'] // 链接、图片、视频
]
const editorOption = {
  placeholder: '请输入',
  theme: 'snow', // 主题
  modules: {
    toolbar: {
      container: toolbarOptions,
      handlers: {
        image: function image () {
          const self = this
          let fileInput = this.container.querySelector('input.ql-image[type=file]')
          fileInput = document.createElement('input')
          fileInput.setAttribute('type', 'file')
          // 设置图片参数名
          if (uploadConfig.name) {
            fileInput.setAttribute('name', uploadConfig.name)
          }
          // 可设置上传图片的格式
          fileInput.setAttribute('accept', uploadConfig.acceptImage)
          fileInput.classList.add('ql-image')
          // 监听选择文件
          fileInput.addEventListener('change', function () {
            loadingInstance = ElLoading.service({
              fullscreen: true,
              text: '上传中...',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            // 创建formData
            const time = new Date().getTime()
            const imgSuffix = (fileInput.files[0].name).substring((fileInput.files[0].name).lastIndexOf('.') + 1)
            getUploadAuthentication({ module: 'congress/quill/' + moment().format('YYYYMMDD'), filename: time + '.' + imgSuffix })
              .then((data) => {
                if (data.status === 200) {
                  const form = new FormData()
                  const obj = data.data.formData
                  for (const i in obj) {
                    form.append(i, obj[i])
                  }
                  form.append('key', data.data.filename)
                  form.append('file', fileInput.files[0])
                  fileUpload({ obj: form, url: data.data.host })
                    .then(res => {
                      if (res.status === 204 || res.status === 200) {
                        const length = self.quill.getSelection(true).index
                        self.quill.insertEmbed(length, 'image', data.data.url)
                        self.quill.setSelection(length + 1)
                      } else {
                        fileInput.value = ''
                      }
                      setTimeout(() => {
                        loadingInstance.close()
                      }, 500)
                    }).catch((err) => {
                      loadingInstance.close()
                      this.$common.statusFn(err.response.status, '上传')
                      this.loading = false
                    })
                } else {
                  loadingInstance.close()
                }
              })
              .catch((err) => {
                loadingInstance.close()
                this.$common.statusFn(err.response.status, '查询信息')
                this.loading = false
              })
          })
          this.container.appendChild(fileInput)
          fileInput.click()
        },
        link: function (value) {
          if (value) {
            const href = prompt('请输入链接地址：')
            this.quill.format('link', href)
          } else {
            this.quill.format('link', false)
          }
        },
        video: function video () {
          const self = this
          let fileInput = this.container.querySelector('input.ql-image[type=file]')
          fileInput = document.createElement('input')
          fileInput.setAttribute('type', 'file')
          // 设置图片参数名
          if (uploadConfig.name) {
            fileInput.setAttribute('name', uploadConfig.name)
          }
          // 可设置上传图片的格式
          fileInput.setAttribute('accept', uploadConfig.acceptVideo)
          fileInput.classList.add('ql-image')
          // 监听选择文件
          fileInput.addEventListener('change', function () {
            loadingInstance = ElLoading.service({
              fullscreen: true,
              text: '上传中...',
              background: 'rgba(0, 0, 0, 0.7)'
            })
            // 创建formData
            const time = new Date().getTime()
            const imgSuffix = (fileInput.files[0].name).substring((fileInput.files[0].name).lastIndexOf('.') + 1)
            getUploadAuthentication({ module: 'congress/quill/' + moment().format('YYYYMMDD'), filename: time + '.' + imgSuffix })
              .then((data) => {
                if (data.status === 200) {
                  const form = new FormData()
                  const obj = data.data.formData
                  for (const i in obj) {
                    form.append(i, obj[i])
                  }
                  form.append('key', data.data.filename)
                  form.append('file', fileInput.files[0])
                  fileUpload({ obj: form, url: data.data.host })
                    .then(res => {
                      if (res.status === 200) {
                        const length = self.quill.getSelection(true).index
                        self.quill.insertEmbed(length, 'video', data.data.url)
                        self.quill.setSelection(length + 1)
                      } else {
                        fileInput.value = ''
                      }
                      setTimeout(() => {
                        loadingInstance.close()
                      }, 500)
                    }).catch((err) => {
                      loadingInstance.close()
                      this.$common.statusFn(err.response.status, '上传')
                      this.loading = false
                    })
                } else {
                  loadingInstance.close()
                }
              })
              .catch((err) => {
                loadingInstance.close()
                this.$common.statusFn(err.response.status, '查询信息')
                this.loading = false
              })
          })
          this.container.appendChild(fileInput)
          fileInput.click()
        }
      }
    }
  }
}
export default {
  editorOption,
  quillInit () {
    for (const item of titleConfig) {
      const tip = document.querySelectorAll('.ql-toolbar ' + item.Choice)
      if (!tip) continue
      for (let i = 0; i < tip.length; i++) {
        tip[i].setAttribute('title', item.title)
      }
    }
  }
}
