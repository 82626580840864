export default {
  routerList: [
    {
      path: '/peoplesLivelihood/collectNoticeSetting',
      component: () => import('@/views/peoplesLivelihood/collectNoticeSetting/index.vue'),
      name: '征集公告设置',
      children: []
    },
    {
      path: '/peoplesLivelihood/collect',
      component: () => import('@/views/peoplesLivelihood/collect/index.vue'),
      name: '项目征集',
      children: []
    },
    {
      path: '/peoplesLivelihood/preliminary',
      component: () => import('@/views/peoplesLivelihood/preliminary/index.vue'),
      name: '项目初定',
      children: []
    },
    {
      path: '/peoplesLivelihood/consider',
      component: () => import('@/views/peoplesLivelihood/consider/index.vue'),
      name: '项目审议',
      children: []
    },
    {
      path: '/peoplesLivelihood/voteDecision',
      component: () => import('@/views/peoplesLivelihood/voteDecision/index.vue'),
      name: '项目票决',
      children: []
    },
    {
      path: '/peoplesLivelihood/putintoeffect',
      component: () => import('@/views/peoplesLivelihood/putintoeffect/index.vue'),
      name: '项目实施',
      children: []
    },
    {
      path: '/peoplesLivelihood/supervise',
      component: () => import('@/views/peoplesLivelihood/supervise/index.vue'),
      name: '项目监督',
      children: []
    },
    {
      path: '/peoplesLivelihood/system',
      component: () => import('@/views/peoplesLivelihood/system/index.vue'),
      name: '管理制度',
      children: []
    },
    {
      path: '/peoplesLivelihood/suggestArchiving',
      component: () => import('@/views/peoplesLivelihood/suggestArchiving/index.vue'),
      name: '建议存档',
      children: []
    }

  ]
}
