import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import selfDirectives from '@/assets/js/directives.js'

import ElementPlus from 'element-plus'// element
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import common from '@/assets/js/common.js'// 公用方法
import moment from 'moment'// 时间插件
import { QuillEditor } from '@vueup/vue-quill'
import '@/assets/icon/iconfont.css' // 引入阿里图标 CSS
import breadcrumb from './components/breadcrumb'// 面包屑组件
import breadcrumbnew from './components/breadcrumbnew'// 面包屑组件
import addressSelection from './components/common/addressSelection'// 地址选择组件
import tableModule from './components/common/tableModule'// 自定义列
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@/assets/css/public.scss'
import '@/assets/css/quillEditor.scss'
import * as echarts from 'echarts' // echarts
import roles from '@/assets/js/userType.js'
import Print from 'vue3-print-nb'
import vueSeamless from 'vue-seamless-scroll/src'
import BaiduMap from 'vue-baidu-map-3x'

const app = createApp(App)
app.config.globalProperties.$common = common
app.config.globalProperties.$moment = moment
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$roles = roles
app.use(selfDirectives) // 自定义指令
app.use(Print)
app.use(BaiduMap, {
  ak: 'YPoexHaIEUOBjqyL4CoEOYnnly8sa6ST'
})
app.component('breadcrumb-box', breadcrumb)
app.component('breadcrumbnew-box', breadcrumbnew)
app.component('address-selection', addressSelection)
app.component('QuillEditor', QuillEditor)
app.component('tableModule', tableModule)
// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    sessionStorage.clear()
  }
  const token = sessionStorage.getItem('token')
  if (!token && to.path !== '/login') {
    next({ path: '/login' })
  } else if (
    to.path === '/login' ||
    to.path === '/mailLists' ||
    to.path === '/home' ||
    to.path === '/terrace' ||
    to.path === '/informationOpen' ||
    to.path === '/performDuties' ||
    to.path === '/secondary' ||
    to.path === '/newsList' ||
    to.path === '/leaderRackWarehouse' ||
    to.path === '/iframeWeb' ||
    to.path === '/largeScreen'
  ) {
    next()
  } else {
    const menuList = JSON.parse(sessionStorage.getItem('menuList'))
    const curToMenu = menuList.filter(item => item.path === to.path)
    if (curToMenu.length > 0) {
      next()
    }
  }
})
// 防止修改sessionStorage
window.addEventListener('storage', (e) => {
  sessionStorage.setItem(e.key, e.oldValue)
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store).use(router).use(ElementPlus, { locale: zhCn }).use(vueSeamless).mount('#app')
