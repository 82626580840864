export default {
  routerList: [
    {
      path: '/governanceInfo',
      component: () => import('@/views/governanceInfo/index.vue'),
      name: '知情明政',
      children: []
    }
  ]
}
