<template>
  <div class="addressSelection-box">
    <!-- 选择省 -->
    <el-select
      :popper-append-to-body="false"
      :style="selectClassFn()"
      style="margin-right:5px"
      v-if="isShowFn(1)"
      :disabled="idDisabled(1)"
      clearable
      :size="sizeRule()"
      @change="provincesChange"
      v-model="addresObj.provinces"
      placeholder="选择省"
    >
      <el-option
        v-for="item in provincesList"
        :key="item.code"
        :label="item.name"
        :value="item.code"
      >
      </el-option>
    </el-select>
    <!-- 选择市 -->
    <el-select
      :popper-append-to-body="false"
      :style="selectClassFn()"
      style="margin-right:5px"
      v-if="isShowFn(2)"
      :disabled="idDisabled(2)"
      clearable
      @change="citiesChange"
      :size="sizeRule()"
      v-model="addresObj.cities"
      placeholder="选择市"
    >
      <el-option
        v-for="item in citiesList"
        :key="item.code"
        :label="item.name"
        :value="item.code"
      >
      </el-option>
    </el-select>
    <!-- 选择县 -->
    <el-select
      :popper-append-to-body="false"
      :style="selectClassFn()"
      style="margin-right:5px"
      v-if="isShowFn(3)"
      :disabled="idDisabled(3)"
      clearable
      @change="areasChange"
      :size="sizeRule()"
      v-model="addresObj.areas"
      placeholder="选择县（区）"
    >
      <el-option
        v-for="item in areasList"
        :key="item.code"
        :label="item.name"
        :value="item.code"
      >
      </el-option>
    </el-select>
    <!-- 选择乡镇（街道） -->
    <el-select
      :popper-append-to-body="false"
      :style="selectClassFn(1)"
      style="margin-right:5px"
      v-if="isShowFn(4)"
      :disabled="idDisabled(4)"
      clearable
      @change="streetsChange"
      :size="sizeRule()"
      v-model="addresObj.streets"
      placeholder="选择乡镇（街道）"
    >
      <el-option
        v-for="item in streetsList"
        :key="item.code"
        :label="item.name"
        :value="item.code"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
// 参数
// let addressSelectPage = {
//   text: ['河南省', '周口市'],
//   code: ['41', '4116'],
//   isSelection: true, // 是否反选
//   isDisabled: true, // 是否禁用
//   disabledNumber: 0, // 禁用数量当isDisabled为true时生效
//   showNumber: 4, // 显示下拉框数量
//   size: 'small'// 尺寸大小
// }
export default {
  props: ['addressSelectPage'],
  data () {
    return {
      keyList: ['provinces', 'cities', 'areas', 'streets', 'village'],
      addressCode: [],
      addressText: [],
      provincesList: this.$common.getprovinces(),
      citiesList: [],
      areasList: [],
      streetsList: [],
      villageList: [],
      addresObj: {
        provinces: '',
        cities: '',
        areas: '',
        streets: ''
      }
    }
  },
  methods: {
    selectClassFn (type) {
      return 'width:180px;'
    },
    // 省change
    provincesChange (e) {
      if (e) {
        this.citiesList = this.$common.getcities(e)
        this.addressCode[0] = e
        this.provincesList.forEach(item => {
          if (item.code === e) {
            this.addressText[0] = item.name
          }
        })
        this.empty(0, true)
      } else {
        this.empty(0, false)
      }
    },
    // 市change
    citiesChange (e) {
      if (e) {
        this.areasList = this.$common.getareas(e)
        this.addressCode[1] = e
        this.citiesList.forEach(item => {
          if (item.code === e) {
            this.addressText[1] = item.name
          }
        })
        this.empty(1, true)
      } else {
        this.empty(1, false)
      }
    },
    // 县change
    areasChange (e) {
      if (e) {
        this.streetsList = this.$common.getstreets(e)
        this.addressCode[2] = e
        this.areasList.forEach(item => {
          if (item.code === e) {
            this.addressText[2] = item.name
          }
        })
        this.empty(2, true)
      } else {
        this.empty(2, false)
      }
    },
    // 乡镇、街道change
    streetsChange (e) {
      if (e) {
        this.addressCode[3] = e
        this.streetsList.forEach(item => {
          if (item.code === e) {
            this.addressText[3] = item.name
          }
        })
        this.empty(3, true)
      } else {
        this.empty(3, false)
      }
    },
    // 清空
    empty (n, bol) {
      if (bol) {
        if (n < this.addressSelectPage.showNumber - 1) {
          this.keyList.forEach((item, index) => {
            if (index > n) {
              this.addresObj[this.keyList[index]] = ''
            }
          })
          this.addressCode.splice(n + 1)
          this.addressText.splice(n + 1)
        }
      } else {
        this.keyList.forEach((item, index) => {
          if (index > n) {
            this.addresObj[this.keyList[index]] = ''
          }
        })
        this.addressCode.splice(n)
        this.addressText.splice(n)
      }
      this.$emit('getAddressData', { code: this.addressCode, text: this.addressText })
    },
    // 禁用规则
    idDisabled (n) {
      if (this.addressSelectPage.isDisabled) {
        if (n <= this.addressSelectPage.disabledNumber) {
          return true
        } else {
          return false
        }
      }
    },
    // 显示规则
    isShowFn (n) {
      if (n <= this.addressSelectPage.showNumber) {
        return true
      } else {
        return false
      }
    },
    // 尺寸大小规则
    sizeRule () {
      return this.addressSelectPage.size || ''
    },
    open () {
      if (this.addressSelectPage.isSelection) {
        [...this.addressCode] = this.addressSelectPage.code;
        [...this.addressText] = this.addressSelectPage.text
        this.getSelectData(this.addressCode)
        for (let i = 0; i < this.keyList.length; i++) {
          if (i <= this.addressCode.length) {
            this.addresObj[this.keyList[i]] = this.addressCode[i]
          } else {
            this.addresObj[this.keyList[i]] = ''
          }
        }
        this.$emit('getAddressData', { code: this.addressCode, text: this.addressText })
      }
    },
    // 编辑时获取下拉框数据
    getSelectData (arr) {
      this.citiesList = []
      this.areasList = []
      this.streetsList = []
      this.villageList = []
      for (let i = 0; i < arr.length; i++) {
        switch (i) {
          case 0:
            this.citiesList = this.$common.getcities(arr[i])
            break
          case 1:
            this.areasList = this.$common.getareas(arr[i])
            break
          case 2:
            this.streetsList = this.$common.getstreets(arr[i])
            break
          default:
        }
      }
    }

  }
}
// 此组件使用说明
//  <address-selection
//       ref="addressselection"
//       v-model:addressSelectPage="addressSelectPage"
//       @getAddressData="getAddressData"
//     ></address-selection>

// addressSelectPage: {
//   text: ['河南省', '周口市'],
//   code: ['41', '4116'],
//   isSelection: true, // 是否反选
//   isDisabled: true, // 是否禁用
//   disabledNumber: 0, // 禁用数量当isDisabled为true时生效
//   showNumber: 4, // 显示下拉框数量
//   size: 'small'// 尺寸大小
// }
</script>
<style lang="scss" scoped>
.addressSelection-box {
  line-height: 32px;
}
</style>
