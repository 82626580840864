function getStyle (obj, attr) {
  const ie = !+'\v1'// 简单判断ie6~8
  if (attr === 'backgroundPosition') { // IE6~8不兼容backgroundPosition写法，识别backgroundPositionX/Y
    if (ie) {
      return obj.currentStyle.backgroundPositionX + ' ' + obj.currentStyle.backgroundPositionY
    }
  }
  if (obj.currentStyle) {
    return obj.currentStyle[attr]
  } else {
    return document.defaultView.getComputedStyle(obj, null)[attr]
  }
}
function getcss (divs, typ) {
  return getStyle(divs, typ)
}
const directives = {
  install: (app) => {
    // 点击按钮一键复制
    app.directive('selfCopy', (el, binding) => {
      el.onclick = () => {
        console.log(binding.value)
        if (!binding.value) {
          alert('不能复制空文本')
          return false
        }
        navigator.clipboard.writeText(binding.value)
        alert('复制成功')
      }
    })
    // 禁止按钮重复点击
    app.directive('dbclick', {
      mounted (el) {
        el.addEventListener('click', e => {
          const a = el
          if (!el.disabled) {
            el.disabled = true
            el.style.color = '#ccc'
            el.style.background = '#f0f0f0'
            el.style.border = '1px solid #f0f0f0'
            setTimeout(() => {
              el.style = a.style
              el.disabled = false
            }, 1000)
          }
        })
      }
    })
    // 拖拽
    app.directive('dialogDrag', {
      mounted (el) {
        el.addEventListener('click', e => {
          const dialogHeaderEl = el.querySelector('.el-dialog__header')
          const dragDom = el.querySelector('.el-dialog')

          dialogHeaderEl.style.cursor = 'move'
          // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
          const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)
          dialogHeaderEl.onmousedown = (e) => {
            const clientHeight = document.body.clientHeight // 获取浏览器可视区高度
            const clientWidth = document.body.clientWidth // 获取浏览器可视区宽度
            document.onselectstart = function () { return false } // 取消页面选中效果
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft
            const disY = e.clientY - dialogHeaderEl.offsetTop
            // 获取到的值带px 正则匹配替换
            let styL, styT

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (sty.left.includes('%')) {
              // eslint-disable-next-line no-useless-escape
              styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
              // eslint-disable-next-line no-useless-escape
              styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
            } else {
              styL = +sty.left.replace(/\px/g, '')
              styT = +sty.top.replace(/\px/g, '')
            }
            document.onmousemove = function (e) {
              // 通过事件委托，计算移动的距离
              const l = e.clientX - disX
              const t = e.clientY - disY

              dragDom.style.left = `${l + styL}px`
              dragDom.style.top = `${t + styT}px`
            }
            document.onmouseup = function (e) {
              const leftStyle = (clientWidth - dragDom.clientWidth) / 2
              const topDistance = dragDom.offsetTop// 获取元素距离浏览器顶部距离
              const leftDistance = dragDom.offsetLeft // 获取元素距离浏览器左侧距离
              const bottomStyle = clientHeight - 42 - parseInt(getcss(dragDom, 'marginTop'))
              if (topDistance < 1) {
                dragDom.style.top = '-' + getcss(dragDom, 'marginTop')
              } else if (clientHeight - topDistance < 42) {
                dragDom.style.top = String(bottomStyle) + 'px'
              }
              if (leftDistance < 1) {
                dragDom.style.left = '-' + String(leftStyle) + 'px'
              } else if (clientWidth - leftDistance - dragDom.clientWidth < 1) {
                dragDom.style.left = String(leftStyle) + 'px'
              }
              document.onselectstart = function () { return true } // 恢复页面选中效果
              document.onmousemove = null
              document.onmouseup = null
            }
          }
        })
      }
    })
  }
}
export default directives
