export default {
  routerList: [
    {
      path: '/tenantNews/news',
      component: () => import('@/views/tenantNews/news/index.vue'),
      name: '新闻管理',
      children: []
    },
    {
      path: '/tenantNews/navigation',
      component: () => import('@/views/tenantNews/navigation/index.vue'),
      name: '导航管理',
      children: []
    },
    {
      path: '/friendlyLinks',
      component: () => import('@/views/friendlyLinks/index.vue'),
      name: '友情链接',
      children: []
    }
  ]
}
