import { createRouter, createWebHashHistory } from 'vue-router'
import home from '@/router/router-item/home.js' // 首页
import newsList from '@/router/router-item/newsList.js' // 新闻列表

import informationOpen from '@/router/router-item/informationOpen.js' // 信息公开
import mailList from '@/router/router-item/mailList.js' // 通讯录
import performDuties from '@/router/router-item/performDuties.js' // 二级菜单页面1

import terrace from '@/router/router-item/terrace.js' // 平台
import secondary from '@/router/router-item/secondary.js' // 二级菜单页面2
import publicView from '@/router/publicView.js'// 公用父级页面

// import terrace from '@/router/router-item/terraceTwo.js' // 平台
// import secondary from '@/router/secondarynew.js' // 新版二级菜单页面

const navRouter = [
  // 重定向到首页
  {
    path: '/index',
    redirect: '/home',
    name: ''
  },
  home.routerObj, // 首页
  newsList.routerObj, // 新闻列表页
  terrace.routerObj, //  平台
  informationOpen.routerObj, //  平台
  mailList.routerObj, //  通讯录
  performDuties.routerObj, // 二级菜单页面
  publicView.routerObj, // 公用父级页面
  secondary.routerObj // 三级菜单页面
]
const routes = [
  // 重定向到登录页面
  {
    path: '/',
    redirect: '/login',
    name: ''
  },
  // 入口页面
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue'),
    children: navRouter
  },
  // 登陆页面
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  // 数据大屏
  {
    path: '/largeScreen',
    name: 'largeScreen',
    component: () => import(/* webpackChunkName: "about" */ '../views/largeScreen/index.vue')
  },
  // 领导架仓
  {
    path: '/leaderRackWarehouse',
    name: 'leaderRackWarehouse',
    component: () => import('../views/leaderRackWarehouse/index.vue')
  },
  // 页面不存在
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: () => import('../views/notFound.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
