export default {
  routerList: [
    {
      path: '/examination/examWorker',
      component: () => import('@/views/examination/examWorker/index.vue'),
      name: '试卷管理',
      children: []
    },
    {
      path: '/examination/myExams',
      component: () => import('@/views/examination/myExams/index.vue'),
      name: '我的考试',
      children: []
    },
    {
      path: '/examination/selftest',
      component: () => import('@/views/examination/selftest/index.vue'),
      name: '自测',
      children: []
    },
    {
      path: '/examination/examDraft',
      component: () => import('@/views/examination/examDraft/index.vue'),
      name: '考试草稿箱',
      children: []
    }
  ]
}
