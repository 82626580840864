export default {
  routerList: [
    {
      path: '/rerformanceRating/onlineAssessment',
      component: () => import('@/views/rerformanceRating/onlineAssessment/index.vue'),
      name: '在线评估',
      children: []
    },
    {
      path: '/rerformanceRating/streetIndex',
      component: () => import('@/views/rerformanceRating/onlineAssessment/streetIndex.vue'),
      name: '在线评估(街道)',
      children: []
    },
    {
      path: '/rerformanceRating/studioIndex',
      component: () => import('@/views/rerformanceRating/onlineAssessment/studioIndex.vue'),
      name: '在线评估(工作室)',
      children: []
    },
    {
      path: '/rerformanceRating/scoreMaintenance',
      component: () => import('@/views/rerformanceRating/scoreMaintenance/index.vue'),
      name: '评分点维护',
      children: []
    },
    {
      path: '/rerformanceRating/templateManagement',
      component: () => import('@/views/rerformanceRating/templateManagement/index.vue'),
      name: '模板管理',
      children: []
    },
    {
      path: '/rerformanceRating/templateLibrarys',
      component: () => import('@/views/rerformanceRating/templateLibrarys/index.vue'),
      name: '模板库管理',
      children: []
    },
    {
      path: '/rerformanceRating/assessmentTask',
      component: () => import('@/views/rerformanceRating/assessmentTask/index.vue'),
      name: '评估任务管理',
      children: []
    },
    {
      path: '/rerformanceRating/resumptionFiles',
      component: () => import('@/views/rerformanceRating/resumptionFiles/index.vue'),
      name: '履职档案',
      children: []
    },
    {
      path: '/rerformanceRating/resumptionReport',
      component: () => import('@/views/rerformanceRating/resumptionReport/index.vue'),
      name: '履职报告',
      children: []
    }
  ]
}
