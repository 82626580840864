export default {
  routerList: [
    {
      path: '/sysManagement/mySchedule',
      component: () => import('@/views/sysManagement/mySchedule/index.vue'),
      name: '我的日程',
      children: []
    },
    {
      path: '/sysManagement/loginStatics',
      component: () => import('@/views/sysManagement/loginStatics/index.vue'),
      name: '登录统计',
      children: []
    },
    {
      path: '/sysManagement/myReminder',
      component: () => import('@/views/sysManagement/myReminder/index.vue'),
      name: '我的待办',
      children: []
    },
    {
      path: '/sysManagement/userGuide',
      component: () => import('@/views/sysManagement/userGuide/index.vue'),
      name: '使用手册',
      children: []
    },
    {
      path: '/sysManagement/documentManagement',
      component: () =>
        import('@/views/sysManagement/documentManagement/documentManagement.vue'),
      name: '人大云盘',
      children: []
    }
  ]
}
