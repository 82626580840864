<template>
  <div class="breadcrumbBox">
    <el-page-header @back="goBack" title="返回">
      <template #content>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="item in routeName" :key="item.path">
            <i
              @click="goHome()"
              v-if="item.path == '/index'"
              class="iconfont icon-quanbuyewu wordColor homeIcon"
            ></i>
            <span
              class="homeIcon"
              @click="goRouter(item)"
              v-if="item.path != '/index'"
              >{{ item.name }}</span
            >
          </el-breadcrumb-item>
        </el-breadcrumb>
      </template>
    </el-page-header>
  </div>
</template>
<script>
import { } from '@/api/api'
import { useRouter } from 'vue-router'
export default {
  name: 'breadcrumbBox',
  components: {
  },
  data () {
    return {
      routeList: []
    }
  },
  setup () {
    const router = useRouter()
    function linkToAbout (e) {
      router.push({
        path: e.path,
        query: { code: e.code }
      })
    }
    return {
      linkToAbout
    }
  },
  computed: {
    routeName () {
      let arr = []
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.routeList = []
      const path = (this.$route.matched[this.$route.matched.length - 1]).path
      const list = JSON.parse(sessionStorage.getItem('menuList'))
      for (let i = 0; i < list.length; i++) {
        if (list[i].path === path && list[i].code === this.$route.query.code) {
          arr = [...[{ path: '/index' }], ...this.createTreeData(list, list[i].code)]
        }
      }
      return arr
    }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    createTreeData (list, pcode) {
      list.forEach((item, index) => {
        if (item.code === pcode) {
          this.routeList.unshift(item)
          this.createTreeData(list, item.pcode)
        }
      })
      return this.routeList
    },
    goRouter (e) {
      this.linkToAbout(e)
    },
    goHome () {
      this.$router.push('/terrace')
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
.breadcrumbBox {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 15px 15px 0 0;
  background: #f9f9f9;
  .homeIcon {
    cursor: pointer;
  }
  .homeIcon:hover {
    opacity: 0.5;
  }
  .el-breadcrumb__item {
    display: flex;
    align-items: center;
  }
  .el-breadcrumb {
    display: flex;
  }
}
</style>
