import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import router from '../router/index'
const systemType = 'congress'

const apiPath = '/api'
// 登录
const loginFn = function name (params) {
  return axios.create({
    baseURL: apiPath,
    auth: params,
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-Requested-System': systemType, 'X-CAPTCHA-TOKEN': params.captchaCode }
  })
}
// 配置axios实例
const http = axios.create({
  baseURL: apiPath
})
const Http = axios.create({
  baseURL: apiPath,
  headers: { 'X-Requested-System': systemType }
})
const exportZip = axios.create({
  baseURL: apiPath,
  responseType: 'blob'
})

// request拦截器
exportZip.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null
    if (token) {
      config.headers['X-Auth-Token'] = token
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-Requested-System'] = systemType
    return config
  },
  err => {
    return Promise.reject(err)
  })
// request拦截器
http.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null
    if (token) {
      config.headers['X-Auth-Token'] = token
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-Requested-System'] = systemType
    return config
  },
  err => {
    return Promise.reject(err)
  })
// 请求结束后
http.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 406:
        ElMessageBox.confirm('登录信息已失效，请重新登录')
          .then(() => {
            router.push('/login')
            history.go(0)
          })
          .catch(() => {
            router.push('/login')
            history.go(0)
          })
        break
      case 401:
        ElMessageBox.confirm('登录信息已失效，请重新登录')
          .then(() => {
            router.push('/login')
            history.go(0)
          })
          .catch(() => {
            router.push('/login')
            history.go(0)
          })
        break
    }
  }

  return Promise.reject(error)
})
export { http, exportZip, systemType }

// 登录
export const login = params => {
  return loginFn(params).get('/oauth2/v1/token').then(response => response)
}
// 获取小程序码
export const getQrcode = params => {
  return exportZip.post('/wx/' + params.appId + '/v1/qrcode', params.data).then(response => response)
}
// 获取登录
export const loginBkInfo = params => {
  return Http.get('captcha/v1/tenant', { params: params }).then(response => response)
}
// 切换用户权限
export const switchPower = params => {
  return http.post('/oauth2/v1/tenant/cut', params).then(response => response)
}
// 添加用户配置
export const addUserPage = params => {
  return http.post('/sys/users/setting/v1/add', params).then(response => response)
}
// 获取用户page
export const getUserPage = params => {
  return http.get('/sys/users/setting/v1/search', { params }).then(response => response)
}
// 查询当前登录用户信息
export const getUserMsg = params => {
  return http.get('/oauth2/v1/me', { params: params }).then(response => response)
}
// 查询用户
export const getUsersSearch = params => {
  return http.get('/auth/tenants/members/v1/search', { params: params }).then(response => response)
}
// 查询用户
export const getUsersPage = params => {
  return http.get('/auth/tenants/members/v1/page', { params: params }).then(response => response)
}
// 查询角色
export const authGroups = params => {
  return http.get('/auth/groups/v1/search', { params: params }).then(response => response)
}
// 查询当前用户角色
export const groupsMembers = params => {
  return http.get('/auth/groups/members/v1/search', { params: params }).then(response => response)
}
// 退出登录
export const logout = params => {
  return http.get('/oauth2/v1/logout', { params: params }).then(response => response)
}
// 文件导出
export const fileUploadBlob = params => {
  return exportZip.get(params.url, { params: params.obj }).then(response => response)
}
// 发送短信
export const sendCode = params => {
  return http.get('/oauth2/v1/phone/code', { params: params }).then(response => response)
}
// 短信登陆
export const phoneLogin = params => {
  return http.post('/oauth2/v1/phone/login', params).then(response => response)
}
// 获取菜单
export const getMenus = params => {
  return http.get('/auth/menus/v1/me', { params: params }).then(response => response)
}

// 新闻查询page
export const articlePage = params => {
  return http.get('/sys/article/v1/page', { params: params }).then(response => response)
}
// 新闻查询Search
export const articleSearch = params => {
  return http.get('/sys/article/v1/search', { params: params }).then(response => response)
}
// 获取上传文件认证
export const getUploadAuthentication = params => {
  return http.get('/sys/files/v1/policy', { params: params }).then(response => response)
}
// 上传文件
export const fileUpload = params => {
  return http.post(params.url, params.obj).then(response => response)
}

// 删除文件
export const fileDelete = params => {
  return http.post('/sys/files/v1/remove', params).then(response => response)
}

// 添加用户常用
export const usedMenuAdd = params => {
  params.systemType = systemType
  return http.post('sys/users/menus/v1/add', params).then(response => response)
}
// 查询用户常用
export const usedMenuSearch = params => {
  params.systemType = systemType
  return http.get('sys/users/menus/v1/search', { params: params }).then(response => response)
}
// 查询用户代表团信息
export const usereDlegation = params => {
  return http.get('/congress-member/rep/query/userid', { params: params }).then(response => response)
}

/* ------------自定义列------------ */
// 添加
export const customcolumnsAdd = params => {
  return http.post('/sys/users/columns/v1/add', params).then(response => response)
}
// 批量修改
export const customcolumnsBatch = params => {
  return http.post('/sys/users/columns/v1/batch', params).then(response => response)
}
// 查询page
export const customcolumnsPage = params => {
  return http.get('/sys/users/columns/v1/page', { params: params }).then(response => response)
}
// 查询search
export const customcolumnsSearch = params => {
  return http.get('/sys/users/columns/v1/search', { params: params }).then(response => response)
}
// 删除
export const customcolumnsDelete = params => {
  return http.delete('/sys/users/columns/v1/delete', { data: params }).then(response => response)
}
// 查询search
export const customcolumnsMe = params => {
  return http.get('/sys/users/columns/v1/me', { params: params }).then(response => response)
}
// 查询数据字典search
export const dictionarySearch = params => {
  params.tenantCode = JSON.parse(sessionStorage.getItem('users')).tenantCode
  params.systemType = systemType
  return http.get('/sys/settings/v1/search', { params: params }).then(response => response)
}

// 届次查询seach
export const subsSearch = params => {
  return http.get('/congress-system/session/manage/v1/search', { params: params }).then(response => response)
}
// 届次查询page
export const subsPage = params => {
  return http.get('/congress-system/session/manage/v1/page', { params: params }).then(response => response)
}

// 获取所有组织
export const getAlldeptInfo = params => {
  return http.get('/sys/organization/v1/search', { params: params }).then(response => response)
}
// 获取所有组织
export const getAlldeptQuery = params => {
  return http.get('/congress-system/organization/v1/query', { params: params }).then(response => response)
}
// 删除组织
export const deleteDepartmentById = params => {
  return http.delete(`/congress-system/organization/v1/${params.id}` + '?executor=' + params.username).then(response => response)
}
// 添加组织
export const addDepartment = params => {
  return http.post('/congress-system/organization/v1', params).then(response => response)
}
// 获取日常
export const getAllmyschedule = params => {
  return http.get('/congress-meeting/daily/v1/page', { params: params }).then(response => response)
}
// 搜索日常
export const searchSchedule = params => {
  return http.get('/congress-meeting/daily/v1/search', { params: params }).then(response => response)
}
// 添加日常
export const addMyschedule = params => {
  return http.post('/congress-meeting/daily/v1', params).then(response => response)
}
// 删除日常
export const dailyDelete = params => {
  return http.delete(`/congress-meeting/daily/v1/delete/${params.uuid}`).then(response => response)
}

// 搜索租户
export const getTenantUserSearch = params => {
  return http.get('/auth/tenants/v1/search', { params: params }).then(response => response)
}
// 编辑租户
export const editTenant = params => {
  return http.put('/auth/tenants/v1/modify', params).then(response => response)
}

// 修改用户密码
export const editUserPwd = params => {
  return http.post('/oauth2/v1/change/password', params).then(response => response)
}

// 租户用户page
export const getTenantUsersPage = params => {
  return http.get('/auth/tenants/members/v1/page', { params: params }).then(response => response)
}
// 获取首拼
export const getStrokes = params => {
  return http.get('/congress-voter/voters/v1/strokes', { params }).then(response => response.data)
}
// 获取获取区域
export const getVillagess = params => {
  return http.get('/sys/villages/v1/search', { params }).then(response => response.data)
}
// 新闻导航查询page
export const navigationPage = params => {
  return http.get('/sys/navigation/v1/page', { params: params }).then(response => response)
}

// 新闻删除
export const articleDelete = params => {
  return http.delete('/sys/article/v1', { data: params }).then(response => response)
}
// 新闻添加
export const articleAdd = params => {
  params.systemType = systemType
  return http.post('/sys/article/v1', params).then(response => response)
}
// 新闻导航查询search
export const navigationSearch = params => {
  return http.get('/sys/navigation/v1/search', { params: params }).then(response => response)
}
// 新闻删除
export const navigationDelete = params => {
  return http.delete('/sys/navigation/v1', { data: params }).then(response => response)
}
// 新闻导航添加
export const navigationAdd = params => {
  return http.post('/sys/navigation/v1', params).then(response => response)
}

// 通知公告page
export const getNoticesPage = params => {
  return http.get('/sys/notices/v1/page', { params: params }).then(response => response)
}

// 添加通知公告
export const addNotices = params => {
  return http.post('/sys/notices/v1/add', params).then(response => response)
}

// 编辑通知公告
export const editNotices = params => {
  return http.put('/sys/notices/v1/modify', params).then(response => response)
}

// 删除通知公告
export const deleteNotices = params => {
  return http.delete('/sys/notices/v1/delete', { data: params }).then(response => response)
}

//  查询我的代办
export const getMyReminder = params => {
  return http.get('/congress-system/pending/message/v1/schedule/reminder', { params: params }).then(response => response)
}

// 友情链接查询
export const friendlyLinksPage = params => {
  return http.get('/sys/settings/links/v1/page', { params: params }).then(response => response)
}

// 友情链接删除
export const friendlyLinksDelete = params => {
  return http.delete('/sys/settings/links/v1/delete', { data: params }).then(response => response)
}
// 友情链接添加
export const friendlyLinksAdd = params => {
  params.systemType = systemType
  return http.post('/sys/settings/links/v1/add', params).then(response => response)
}
// 友情链接修改
export const friendlyLinksModify = params => {
  return http.put('/sys/settings/links/v1/modify', params).then(response => response)
}

// 查询使用手册
export const manualSearch = params => {
  return http.get('sys/manual/v1/page', { params: params }).then(response => response)
}

// 初始化用户自定义列
export const initUsersSelfList = params => {
  return http.get('/sys/users/columns/v1/reset', { params: params }).then(response => response)
}

// 政协云盘

// 添加
export const documentAddFile = params => {
  return http.post('/congress-system/cloud/disk/v1', params).then(response => response)
}

// 查询Search
export const getDocumentFileSearch = params => {
  return http.get('/congress-system/cloud/disk/v1/search', { params: params }).then(response => response)
}

// 查询Page
export const getDocumentFilePage = params => {
  return http.get('/congress-system/cloud/disk/v1/page', { params: params }).then(response => response)
}

// 假删除
export const noDeleteDocumentFile = params => {
  return http.delete('/congress-system/cloud/disk/v1', { data: params }).then(response => response)
}

// 彻底删除
export const deleteDocumentFile = params => {
  return http.delete('/congress-system/cloud/disk/v1/physics', { data: params }).then(response => response)
}

// 查询角色page
export const groupsSearch = params => {
  return http.get('/auth/groups/v1/search', { params: params }).then(response => response)
}

