export default {
  routerList: [
    {
      path: '/liaison/liaisonManagement',
      component: () => import('@/views/liaison/liaisonManagement/index.vue'),
      name: '所有代表联络站',
      children: []
    },
    {
      path: '/liaison/liaisonActivity',
      component: () => import('@/views/liaison/liaisonActivity/index.vue'),
      name: '代表联络站新闻发布',
      children: []
    },
    {
      path: '/liaison/lawList',
      component: () => import('@/views/liaison/lawList/index.vue'),
      name: '法律法规',
      children: []
    },
    {
      path: '/liaison/repMotto',
      component: () => import('@/views/liaison/repMotto/index.vue'),
      name: '代表一句话',
      children: []
    },
    {
      path: '/liaison/liaisonLegion',
      component: () => import('@/views/liaison/liaisonLegion/index.vue'),
      name: '区域管理',
      children: []
    },
    {
      path: '/liaison/liaisonSetting',
      component: () => import('@/views/liaison/liaisonSetting/index.vue'),
      name: '代表工作室人员管理',
      children: []
    },
    {
      path: '/liaison/liaisonSettingTwo',
      component: () => import('@/views/liaison/liaisonSettingTwo/index.vue'),
      name: '代表工作室人员管理2',
      children: []
    },
    {
      path: '/liaison/liaisonProposal',
      component: () => import('@/views/liaison/liaisonProposal/index.vue'),
      name: '联络站建议',
      children: []
    },
    {
      path: '/liaison/statistics',
      component: () => import('@/views/liaison/statistics/index.vue'),
      name: '联络站统计',
      children: []
    },
    {
      path: '/liaison/liaisonNesCheck',
      component: () => import('@/views/liaison/liaisonNesCheck/index.vue'),
      name: '联络站新闻审核',
      children: []
    }
  ]
}
