<template>
  <div class="tableHeight">
    <!-- table -->
    <el-table
      :stripe="!isRowBg"
      :row-class-name="tableRowClassName"
      v-loading="tableConfig.loading"
      @sort-change="isSortableAble"
      :data="tableConfig.tableData"
      append-to-body
      height="100%"
      style="width: 100%"
      :show-summary="summaryList.length"
      sum-text="总计"
      :summary-method="getSummaries"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:empty>
        <img src="../../assets/images/nodata.png" alt="" />
      </template>
      <!-- 选择框 -->
      <el-table-column
        type="selection"
        align="center"
        width="60px"
        v-if="headerShowcheckBox && rowList.length"
      >
      </el-table-column>
      <template v-for="(i, x) in rowList">
        <el-table-column
          :label="i.label"
          :key="x"
          :min-width="i.width"
          :align="i.cellStyle"
          :show-overflow-tooltip="i.isHide"
          :fixed="i.extend.fixedWay ? i.extend.fixedWay : false"
          :sortable="i.isSortable ? 'custom' : false"
          v-if="i.isShow"
          :property="i.propName"
        >
          <template #default="scope">
            <!-- 图标 -->
            <span v-if="i.type === '01'">
              <i
                class="iconfont wordColor itemIcon"
                @click="itemClick(i, scope.row)"
                :class="i.isEdit ? i.icon + ' ' + 'cursor' : i.icon"
              ></i>
            </span>
            <!-- 需要转换 -->
            <span v-if="i.type === '02'">
              <span v-if="i.extend.isEditType !== 'none'">
                <!-- scope.row[i.propName] -->
                <el-tag
                  v-if="
                    $common.transformationFn(
                      this.dictionaryObj[i.dictionaryType],
                      propNameFn(scope.row, i.propName)
                    )
                  "
                  :type="i.extend.isEditType"
                  effect="light"
                >
                  <span
                    @click="itemClick(i, scope.row)"
                    :class="i.isEdit ? 'cursor' : ''"
                  >
                    {{
                      $common.transformationFn(
                        this.dictionaryObj[i.dictionaryType],
                        propNameFn(scope.row, i.propName)
                      )
                    }}
                  </span>
                </el-tag>
              </span>
              <span v-if="i.extend.isEditType === 'none'">{{
                $common.transformationFn(
                  this.dictionaryObj[i.dictionaryType],
                  propNameFn(scope.row, i.propName)
                )
              }}</span>
            </span>

            <!-- 文本 -->
            <span v-if="i.type === '03'">
              <span @click="itemClick(i, scope.row)" :class="nameClassFn(i)">
                {{ propNameFn(scope.row, i.propName) }}
              </span>
            </span>
            <!-- 展示头像 -->
            <span v-if="i.type === '04'">
              <p>
                <el-tooltip class="item" placement="bottom" effect="light">
                  <template #content>
                    <div class="photoBox">
                      <img
                        v-if="propNameFn(scope.row, i.extend.picture)"
                        :src="propNameFn(scope.row, i.extend.picture)"
                        alt
                      />
                      <img
                        v-if="!propNameFn(scope.row, i.extend.picture)"
                        :src="require('../../assets/images/nophoto.png')"
                        alt
                      />
                    </div>
                  </template>
                  <span
                    @click="itemClick(i, scope.row)"
                    :class="nameClassFn(i)"
                  >
                    {{ propNameFn(scope.row, i.propName) }}
                  </span>
                </el-tooltip>
              </p>
            </span>
            <div v-if="i.type === '05'">
              <el-button
                size="small"
                :icon="i.icon ? i.icon : ''"
                v-show="i.show"
                v-for="(i, x) in operateList"
                :key="x"
                @click="operateClcik(i, scope.row)"
                :type="i.type"
                :title="i.title"
                >{{ i.name }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- 显示列弹窗 -->
    <el-dialog
      title="自定义显示列"
      class="addDialog"
      center
      :model-value="dialogVisible"
      draggable
      :before-close="handleClose"
      width="70%"
      @open="open"
    >
      <div class="dialog-form">
        <el-table stripe :data="showList" style="width: 100%" height="100%">
          <template v-slot:empty>
            <img src="../../assets/images/nodata.png" alt="" />
          </template>
          <el-table-column
            align="center"
            prop="label"
            label="显示列名称"
            :min-width="120"
          />
          <el-table-column
            align="center"
            prop="isShow"
            label="是否显示"
            :min-width="110"
          >
            <template #default="scope">
              <el-switch v-model="scope.row.isShow" />
            </template>
          </el-table-column>
          <!-- 开发人员使用,不允许用户修改 -->
          <!-- <el-table-column
            align="center"
            prop="isEdit"
            label="是否可点击"
            :min-width="110"
          >
            <template #default="scope">
              <el-switch v-model="scope.row.isEdit" />
            </template>
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="sortNo"
            label="排序号"
            :min-width="180"
          >
            <template #default="scope">
              <el-input-number
                class="inputWidth"
                v-model="scope.row.sortNo"
                :min="1"
              />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="width"
            label="宽度"
            :min-width="180"
          >
            <template #default="scope">
              <el-input-number
                class="inputWidth"
                v-model="scope.row.width"
                :min="60"
                :max="500"
              />
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="isHide"
            label="超出是否隐藏"
            :min-width="110"
          >
            <template #default="scope">
              <el-switch
                :disabled="scope.row.type === '05'"
                v-model="scope.row.isHide"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 确定按钮 -->
      <template #footer>
        <span class="dialog-footer">
          <el-button v-dbclick type="primary" @click="submitFn" class="submit"
            >确定</el-button
          >
          <el-button type="danger" @click="resettingList">初始化</el-button>
          <el-button @click="handleClose">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { customcolumnsMe, dictionarySearch, customcolumnsBatch, initUsersSelfList } from '@/api/api.js'
export default {
  name: 'tableModule',
  components: {},
  props: [
    'isRowBg', 'operateList'
  ],
  data () {
    return {
      summaryList: [],
      dialogVisible: false,
      headerShowcheckBox: true,
      tableConfig: this.$store.state.tableConfig,
      rowList: [],
      showList: [],
      dictionaryObj: {}
    }
  },
  watch: {
  },
  computed: {
  },
  mounted () { },
  created () {
    this.getcustomcolumns()
  },
  methods: {
    operateClcik (e, row) {
      this.$emit('operateFn', { method: e.method, data: row, parameter: e.parameter })
    },
    nameClassFn (e) {
      if (e.isEdit) {
        if (e.propName === 'name' || e.propName === 'massesName' || e.propName === 'repName') {
          return 'cursor blueColor'
        } else {
          return 'cursor wordColor'
        }
      }
    },
    tableRowClassName ({ row }) {
      if (this.isRowBg) {
        if (row.rowBg === 'warning') {
          return 'warning-row'
        } else if (row.rowBg === 'error') {
          return 'error-row'
        } else if (row.rowBg === 'success') {
          return 'success-row'
        }
        return ''
      }
      return ''
    },
    // 合计
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (this.summaryList.indexOf(column.property) > -1) {
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    // 获取所有列
    getcustomcolumns () {
      const menusCode = this.getMenuCode((this.$route.matched[this.$route.matched.length - 1]).path) || 'nodata'
      customcolumnsMe({
        menuCode: menusCode,
        sortField: 'sortNo'
      }).then(res => {
        if (res.status === 200) {
          if (res.data.length) {
            res.data.forEach(item => {
              item.sortNo = Number(item.sortNo)
              item.width = Number(item.width)
            })
            this.rowList = res.data
            this.summaryList = []
            for (let i = 0; i < this.rowList.length; i++) {
              if (this.rowList[i].extend) {
                if (this.rowList[i].extend.isSummary) {
                  this.summaryList.push(this.rowList[i].propName)
                }
              }
            }
            this.dictionaryFn(res.data)
          } else {
            this.$message.warning('请添加自定义列')
          }
        } else {
          this.$message.warning('获取自定义列失败！')
        }
      }).catch(() => {
        this.$message.warning('获取自定义列失败！')
      })
    },

    propNameFn (row, name) {
      if (name.indexOf('.') > -1) {
        const arr = name.split('.')
        return row[arr[0]][arr[1]]
      } else {
        return row[name]
      }
    },
    handleSelectionChange (val) {
      // table checkbox 选中项的值
      this.multipleSelection = val
      this.$emit('getSelected', val)
    },
    // 根据路径获取code
    getMenuCode (path) {
      // const arr = JSON.parse(sessionStorage.getItem('menuList'))
      // for (let i = 0; i < arr.length; i++) {
      //   if (arr[i].path === path) {
      //     return arr[i].code
      //   }
      // }
      let code = ''
      if (this.getQueryVariable('code')) {
        code = this.getQueryVariable('code')
      } else {
        code = sessionStorage.getItem('menuCode')
          ? sessionStorage.getItem('menuCode')
          : ''
      }
      return code
    },
    getQueryVariable (variable) {
      if (window.location.hash.indexOf(variable + '=') !== -1) {
        const url = window.location.hash.split('?')[1].split('&')
        for (let i = 0; i < url.length; i++) {
          const pair = url[i].split('=')
          if (pair[0] === variable) {
            return pair[1]
          }
        }
      }
      return false
    },
    // 点击排序时触发
    isSortableAble (e) {
      this.$emit('clickSort', e)
    },
    open () { },
    // 点击单元格
    itemClick (i, row) {
      if (i.isEdit) {
        this.$emit('clcikCells', row, i.propName)
      }
    },
    // 关闭显示列弹窗
    handleClose () {
      this.dialogVisible = false
    },
    // 打开显示列弹窗
    handleShow () {
      this.dialogVisible = true
      this.showList = JSON.parse(JSON.stringify(this.rowList))
    },
    resettingList () {
      initUsersSelfList({
        username: JSON.parse(sessionStorage.getItem('users')).username,
        tenantCode: JSON.parse(sessionStorage.getItem('users')).tenantCode,
        menuCode: this.getMenuCode((this.$route.matched[this.$route.matched.length - 1]).path) || 'nodata'
      }).then(res => {
        if (res.status === 200) {
          this.$message.success('操作成功')
          this.handleClose()
          this.getcustomcolumns()
        }
      }).catch(() => {
        this.$message.warning('操作失败')
      })
    },
    // 显示列提交
    submitFn () {
      const arr = JSON.parse(JSON.stringify(this.showList))
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].tenantCode === '0' && arr[i].username === '0') {
          delete arr[i].code
          delete arr[i].id
          arr[i].username = JSON.parse(sessionStorage.getItem('users')).username
          arr[i].tenantCode = JSON.parse(sessionStorage.getItem('users')).tenantCode
        }
      }
      customcolumnsBatch(arr).then(res => {
        if (res.status === 200) {
          this.handleClose()
          this.getcustomcolumns()
        } else {
          this.$message.warning('修改失败')
        }
      }).catch(() => {
        this.$message.warning('修改失败')
      })
    },
    // 数据字典转换
    dictionaryFn (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].type === '02') {
          dictionarySearch({ type: arr[i].dictionaryType }).then(res => {
            if (res.data.length) {
              this.dictionaryObj[[arr[i].dictionaryType]] = res.data[0].content
            }
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.blueColor {
  color: #247def;
}
::v-deep(.warning-row) {
  background: #fdf6ec;
}
::v-deep(.error-row) {
  background: #fef0f0;
}
::v-deep(.success-row) {
  background: #f0f9eb;
}
.cursor {
  cursor: pointer;
}
.photoBox {
  background: #fff;
  width: 80px;
  height: 100px;
  color: black;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
