export default {
  routerList: [
    {
      path: '/z/liaisonDevice',
      component: () => import('@/views/deviceManage/liaisonDevice/index.vue'),
      name: '联络站设备管理',
      children: []
    }
  ]
}
