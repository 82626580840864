export default {
  routerList: [
    {
      path: '/question/workorder',
      component: () => import('@/views/question/workorder/index.vue'),
      name: '所有问卷',
      children: []
    },
    {
      path: '/question/questionDraft',
      component: () => import('@/views/question/questionDraft/index.vue'),
      name: '草稿箱',
      children: []
    },
    {
      path: '/repQuestion/repAllQuestion',
      component: () => import('@/views/repQuestion/repAllQuestion/index.vue'),
      name: '代表问卷',
      children: []
    },
    {
      path: '/repQuestion/myQuestion',
      component: () => import('@/views/repQuestion/myQuestion/index.vue'),
      name: '我的答卷',
      children: []
    }
  ]
}
