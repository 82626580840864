export default {
  routerList: [
    {
      path: '/supervise/allSupervise',
      component: () => import('@/views/supervise/allSupervise/index.vue'),
      name: '所有事项',
      children: []
    },
    {
      path: '/supervise/audit',
      component: () => import('@/views/supervise/audit/index.vue'),
      name: '待审核微建议',
      children: []
    },
    {
      path: '/supervise/processing',
      component: () => import('@/views/supervise/processing/index.vue'),
      name: '待处理微建议',
      children: []
    },
    {
      path: '/supervise/waitHandling',
      component: () => import('@/views/supervise/waitHandling/index.vue'),
      name: '待交办微建议',
      children: []
    },
    {
      path: '/supervise/waitHandle',
      component: () => import('@/views/supervise/waitHandle/index.vue'),
      name: '待办理微建议',
      children: []
    },
    {
      path: '/supervise/replied',
      component: () => import('@/views/supervise/replied/index.vue'),
      name: '已答复微建议',
      children: []
    },
    {
      path: '/supervise/noAccept',
      component: () => import('@/views/supervise/noAccept/index.vue'),
      name: '不予受理微建议',
      children: []
    },
    {
      path: '/supervise/townshipSup',
      component: () => import('@/views/supervise/townshipSup/index.vue'),
      name: '乡镇微建议',
      children: []
    },
    {
      path: '/supervise/opensupervise',
      component: () => import('@/views/supervise/opensupervise/index.vue'),
      name: '公开微建议',
      children: []
    },
    {
      path: '/supervise/adjustment',
      component: () => import('@/views/supervise/adjustment/index.vue'),
      name: '申请调整微建议',
      children: []
    }
  ]
}
