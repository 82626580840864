export default {
  routerList: [
    {
      path: '/iframeWeb',
      component: () => import('@/views/iframeWeb/index.vue'),
      name: 'iframeWeb',
      children: []
    }
  ]
}
