export default {
  routerList: [
    {
      path: '/representative/representativeManage',
      component: () => import('@/views/representative/representativeManage/index.vue'),
      name: '代表信息管理',
      children: []
    },
    {
      path: '/representative/nationwideRepresent',
      component: () => import('@/views/representative/nationwideRepresent/index.vue'),
      name: '全国代表信息管理',
      children: []
    },
    {
      path: '/representative/provinceRepresentManage',
      component: () => import('@/views/representative/provinceRepresentManage/index.vue'),
      name: '省代表信息管理',
      children: []
    },
    {
      path: '/representative/cityRepresentManage',
      component: () => import('@/views/representative/cityRepresentManage/index.vue'),
      name: '市代表信息管理',
      children: []
    },
    {
      path: '/representative/countyRepresent',
      component: () => import('@/views/representative/countyRepresent/index.vue'),
      name: '县（区）代表信息管理',
      children: []
    },
    {
      path: '/representative/townRepresent',
      component: () => import('@/views/representative/townRepresent/index.vue'),
      name: '乡镇（街道）代表信息管理',
      children: []
    },
    {
      path: '/representative/stopRepresentManage',
      component: () => import('@/views/representative/stopRepresentManage/index.vue'),
      name: '终止代表信息管理',
      children: []
    },
    {
      path: '/representative/previousRepresent',
      component: () => import('@/views/representative/previousRepresent/index.vue'),
      name: '往届代表信息管理',
      children: []
    },
    {
      path: '/representative/previousMeetingRep',
      component: () => import('@/views/representative/previousMeetingRep/index.vue'),
      name: '往届次人代会参会名单',
      children: []
    },
    {
      path: '/representative/hasDeleteRepresent',
      component: () => import('@/views/representative/hasDeleteRepresent/index.vue'),
      name: '已删除代表管理',
      children: []
    },
    {
      path: '/representative/standingCommRep',
      component: () => import('@/views/representative/standingCommRep/index.vue'),
      name: '常委信息管理',
      children: []
    },
    {
      path: '/representative/resInfoCount',
      component: () => import('@/views/representative/resInfoCount/index.vue'),
      name: '代表信息统计',
      children: []
    }
  ]
}
