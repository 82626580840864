
export default {
  routerList: [
    {
      path: '/voterRegister/register',
      component: () => import('@/views/voterRegister/register/index.vue'),
      name: '选民登记',
      children: []
    },
    {
      path: '/voterRegister/batchSetting',
      component: () => import('@/views/voterRegister/batchSetting/index.vue'),
      name: '批量设置',
      children: []
    },
    {
      path: '/voterRegister/candidate',
      component: () => import('@/views/voterRegister/candidate/index.vue'),
      name: '候选人管理',
      children: []
    },
    {
      path: '/voterRegister/regionVoterRegister',
      component: () => import('@/views/voterRegister/regionVoterRegister/index.vue'),
      name: '选区管理',
      children: []
    }
  ]
}
