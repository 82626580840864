export default {
  routerList: [
    {
      path: '/meeting/allMetting',
      component: () => import('@/views/meeting/allMeeting/index.vue'),
      name: '所有会议',
      children: []
    },
    {
      path: '/meeting/pendingApproval',
      component: () => import('@/views/meeting/pendingApproval/index.vue'),
      name: '待审核会议',
      children: []
    },
    {
      path: '/meeting/draft',
      component: () => import('@/views/meeting/draft/index.vue'),
      name: '草稿箱会议',
      children: []
    },
    {
      path: '/meeting/myLeave',
      component: () => import('@/views/meeting/myLeave/index.vue'),
      name: '我的请假会议',
      children: []
    },
    {
      path: '/meeting/pendingLeave',
      component: () => import('@/views/meeting/pendingLeave/index.vue'),
      name: '待审核请假会议',
      children: []
    },
    {
      path: '/meeting/resCount',
      component: () => import('@/views/meeting/resCount/index.vue'),
      name: '会议统计',
      children: []
    },
    {
      path: '/meeting/application',
      component: () => import('@/views/meeting/application/index.vue'),
      name: '会议报名',
      children: []
    },
    {
      path: '/meeting/applicationExamine',
      component: () => import('@/views/meeting/applicationExamine/index.vue'),
      name: '会议报名审核',
      children: []
    }
  ]
}
