// import axios from 'axios'
// 中国省级（省份、直辖市、自治区），地级（城市），县级（区县），乡级（乡镇、街道），村级（村委会、居委会）
import provinces from '../../../public/static/china/provinces.json'
import cities from '../../../public/static/china/cities.json'
import areas from '../../../public/static/china/areas.json'
import streets from '../../../public/static/china/streets.json'
const chinaList = {
  provinces: provinces,
  cities: cities,
  areas: areas,
  streets: streets
}
// axios.get('/ossApi/china/provinces.json').then(res => {
//   chinaList.provinces = res.data
// })
// axios.get('ossApi/china/cities.json').then(res => {
//   chinaList.cities = res.data
// })
// axios.get('ossApi/china/areas.json').then(res => {
//   chinaList.areas = res.data
// })
// axios.get('ossApi/china/streets.json').then(res => {
//   chinaList.streets = res.data
// })
// 更换皮肤集合
const predefineColors = ['#FA4138', '#3388F8', '#01C789', '#9958DC', '#F7889C', '#344A7B']

export default {
  predefineColors,
  chinaList
}
