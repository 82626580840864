export default {
  routerList: [
    {
      path: '/activity/allActivity',
      component: () => import('@/views/activity/allActivity/index.vue'),
      name: '所有活动',
      children: []
    },
    {
      path: '/activity/pendingApproval',
      component: () => import('@/views/activity/pendingApproval/index.vue'),
      name: '待审核活动',
      children: []
    },
    {
      path: '/activity/draft',
      component: () => import('@/views/activity/draft/index.vue'),
      name: '草稿箱活动',
      children: []
    },
    {
      path: '/activity/myLeave',
      component: () => import('@/views/activity/myLeave/index.vue'),
      name: '我的请假活动',
      children: []
    },
    {
      path: '/activity/pendingLeave',
      component: () => import('@/views/activity/pendingLeave/index.vue'),
      name: '待审核请假活动',
      children: []
    },
    {
      path: '/activity/activityEscalation',
      component: () => import('@/views/activity/activityEscalation/index.vue'),
      name: '代表打卡',
      children: []
    },
    {
      path: '/activity/application',
      component: () => import('@/views/activity/application/index.vue'),
      name: '活动报名',
      children: []
    },
    {
      path: '/activity/applicationExamine',
      component: () => import('@/views/activity/applicationExamine/index.vue'),
      name: '活动报名审核',
      children: []
    }
  ]
}
