export default {
  routerList: [
    {
      path: '/clouDisk/index',
      component: () => import('@/views/clouDisk/index.vue'),
      name: '人大云盘',
      children: []
    }
  ]
}
