
export default {
  routerList: [
    {
      path: '/trigeminy/connectMasses',
      component: () => import('@/views/trigeminy/connectMasses/index.vue'),
      name: '代表联系群众',
      children: []
    }
  ]
}
