export default {
  routerList: [
    {
      path: '/study/studyTraining',
      component: () => import('@/views/study/studyTraining/index.vue'),
      name: '学习培训管理',
      children: []
    },
    {
      path: '/study/myStudy',
      component: () => import('@/views/study/myStudy/index.vue'),
      name: '我的学习情况',
      children: []
    },
    {
      path: '/study/studyAssessment',
      component: () => import('@/views/study/studyAssessment/index.vue'),
      name: '学习考核',
      children: []
    },
    {
      path: '/study/assessmentRule',
      component: () => import('@/views/study/assessmentRule/index.vue'),
      name: '考核活动',
      children: []
    },
    {
      path: '/study/eBOOK',
      component: () => import('@/views/study/eBOOK/index.vue'),
      name: '电子书籍',
      children: []
    },
    {
      path: '/study/eBookManage',
      component: () => import('@/views/study/eBookManage/index.vue'),
      name: '电子书籍管理',
      children: []
    },
    {
      path: '/study/statistics',
      component: () => import('@/views/study/statistics/index.vue'),
      name: '学习培训统计',
      children: []
    }
    // {
    //   path: '/study/studyContent',
    //   component: () => import('@/views/study/studyContent/index.vue'),
    //   name: '学习内容',
    //   children: []
    // }
  ]
}
