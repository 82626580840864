
import sysManagement from '@/router/router-item/sysManagement.js' // 系统管理
import iframeWeb from '@/router/router-item/iframeWeb.js' // 系统管理
import representative from '@/router/router-item/representative.js' // 代表
import suggest from '@/router/router-item/suggest.js' // 建议
import activity from '@/router/router-item/activity.js' // 活动
import meeting from '@/router/router-item/meeting.js' // 会议
import advice from '@/router/router-item/advice.js' // 意见征集
import study from '@/router/router-item/study.js' // 学习培训
import rerformanceRating from '@/router/router-item/rerformanceRating.js' // 履职评价
import lettersVisits from '@/router/router-item/lettersVisits.js' // 网上信访
import liaison from '@/router/router-item/liaison.js' // 联络站
import supervise from '@/router/router-item/supervise.js' // 微建议
import deviceManage from '@/router/router-item/deviceManage.js' // 设备管理
import addressBookManage from '@/router/router-item/addressBookManage.js' // 通讯录管理
import trigeminy from '@/router/router-item/trigeminy.js' // 通讯录管理
import voterRegister from '@/router/router-item/voterRegister.js' // 选民
import question from '@/router/router-item/question.js' // 问卷
import examination from '@/router/router-item/examination.js' // 考试
import peoplesLivelihood from '@/router/router-item/peoplesLivelihood.js' // 民生实事
import tenantNews from '@/router/router-item/tenantNews.js' // 文章管理
import tenantNotice from '@/router/router-item/tenantNotice.js' // 通知公告
import governanceInfo from '@/router/router-item/governanceInfo.js' // 通知公告
import dataCenter from '@/router/router-item/dataCenter.js' // 数据中心
import solicitationOpinions from '@/router/router-item/solicitationOpinions.js' // 数据中心
import clouDisk from '@/router/router-item/clouDisk.js' // 人大云盘

export default {
  routerObj: {
    path: '/publicView',
    component: () => import('@/views/publicView.vue'),
    name: 'publicView',
    children: [
      ...sysManagement.routerList, // 系统管理
      ...representative.routerList, // 代表
      ...suggest.routerList, // 建议
      ...activity.routerList, // 活动
      ...meeting.routerList, // 会议
      ...advice.routerList, // 意见征集
      ...liaison.routerList, // 联络站
      ...study.routerList, // 学习培训
      ...rerformanceRating.routerList, // 履职评价
      ...lettersVisits.routerList, // 网上信访
      ...supervise.routerList, // 微建议
      ...deviceManage.routerList, // 设备管理
      ...iframeWeb.routerList, // 设备管理
      ...addressBookManage.routerList, // 通讯录管理
      ...trigeminy.routerList, // 意见征集
      ...voterRegister.routerList, // 选民
      ...question.routerList, // 问卷
      ...examination.routerList, // 考试
      ...peoplesLivelihood.routerList, // 民生实事
      ...tenantNews.routerList, // 文章管理
      ...tenantNotice.routerList, // 通知公告
      ...governanceInfo.routerList, // 知情明政
      ...dataCenter.routerList, // 数据中心
      ...solicitationOpinions.routerList, // 意见征集
      ...clouDisk.routerList // 人大云盘
    ]
  }

}
